<template>
    <div>
        <el-drawer  size="70%" title="时间设置" :with-header="false" @closed="closeDrawer"
                   :visible.sync="customTime" direction="rtl" custom-class="custom-drawer">
            <i @click="closeDrawer" class="btn-close el-icon-close"></i>
            <div class="custom-budget-content">
                <div class="title">时间设置</div>
                <div class="item">分时折扣设置</div>
                <div class="item">1.分时段折扣功能允许您对不同时段设置不同的折扣出价，折扣出价计算公式为：X% * 出价（X为时段折扣系数），100%即为无折扣。</div>
                <div class="item">2.鼠标悬浮于某个时间段即可查看该时间段的折扣出价。设置折扣出价时支持鼠标点选某个时间段或者框选多个时间段。</div>
                <el-radio-group v-model="ruleForm.show_type" style="margin-top: 40px" @change="selectShowWay" class="radioGroup-red">
                    <el-radio :label="1" ><span :class="{'isclick':isClick==2}">整天展示</span></el-radio>
                    <el-radio :label="2"><span :class="{'isclick':isClick==1}">自定义</span></el-radio>
                </el-radio-group>

                <div class="table-time">
                    <div class="line header">
                        <div class="line-left"></div>
                        <div class="line-right">
                            <div class="item">00:00-06:00</div>
                            <div class="item">06:00-12:00</div>
                            <div class="item">12:00-18:00</div>
                            <div class="item">18:00-24:00</div>
                        </div>
                    </div>
                    <div class="line line-body">
                        <div class="line-left">时段</div>
                        <div class="line-right">
                            <div class="item">
                                <template v-for="(timeItem, timeIndex) in ruleForm.time_frame_data">
                                    <template v-if="timeIndex < 6">
                                        <div class="timer" @click="openSingleTime(timeItem, timeIndex, $event)"
                                             :class="timeItem.type === 0 ? '' : 'current'"
                                             :key="`time_frame_data_${timeIndex}`">
                                            <div class="timer-hover">
                                                <div>时段：{{timeItem.timezone}}</div>
                                                <div>{{timeItem.percent}}%折扣</div>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </div>
                            <div class="item">
                                <template v-for="(timeItem, timeIndex) in ruleForm.time_frame_data">
                                    <template v-if="timeIndex >= 6 && timeIndex < 12">
                                        <div class="timer" @click="openSingleTime(timeItem, timeIndex, $event)"
                                             :class="timeItem.type === 0 ? '' : 'current'"
                                             :key="`time_frame_data_${timeIndex}`">
                                            <div class="timer-hover">
                                                <div>时段：{{timeItem.timezone}}</div>
                                                <div>{{timeItem.percent}}%折扣</div>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </div>
                            <div class="item">
                                <template v-for="(timeItem, timeIndex) in ruleForm.time_frame_data">
                                    <template v-if="timeIndex >= 12 && timeIndex < 18">
                                        <div class="timer" @click="openSingleTime(timeItem, timeIndex, $event)"
                                             :class="timeItem.type === 0 ? '' : 'current'"
                                             :key="`time_frame_data_${timeIndex}`">
                                            <div class="timer-hover">
                                                <div>时段：{{timeItem.timezone}}</div>
                                                <div>{{timeItem.percent}}%折扣</div>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </div>
                            <div class="item">
                                <template v-for="(timeItem, timeIndex) in ruleForm.time_frame_data">
                                    <template v-if="timeIndex >= 18">
                                        <div class="timer" @click="openSingleTime(timeItem, timeIndex, $event)"
                                             :class="timeItem.type === 0 ? '' : 'current'"
                                             :key="`time_frame_data_${timeIndex}`">
                                            <div class="timer-hover">
                                                <div>时段：{{timeItem.timezone}}</div>
                                                <div>{{timeItem.percent}}%折扣</div>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item">温馨提示：默认无折扣100%，可以拖拽鼠标选择投放时间段并自定义折扣。</div>
                <div class="operate-box">
                    <el-button @click="closeCustomTime" class="btn-w-140 btn-red" style="width: 120px">确定</el-button>
                </div>
            </div>
        </el-drawer>

        <!--        设置单个时间-->
        <div class="custom-time-wrapper" v-show="clickData.show" ref="clickDataRef">
            <div class="title">时段：{{clickData.timezone}}</div>
            <el-radio-group v-model="clickData.type" class="radioGroup-red one-line">
                <el-radio :label="2" style="margin-bottom: 10px">自定义</el-radio>
                <el-input-number class="number-input" ref="clickDataPercent" v-model="clickData.percent" :precision="0" :disabled="clickData.type !== 2"
                                 :controls="false" placeholder="30~500的整数"></el-input-number>
                <span style="font-size: 18px; margin-left: 5px">%</span>
                <el-radio :label="1">无折扣</el-radio>
                <el-radio :label="0">不投放</el-radio>
            </el-radio-group>
            <div class="bottom-box">
                <el-button class="btn-red-line" size="medium" @click="reSetClickData">取消</el-button>
                <el-button class="btn-red" size="medium" @click="sureCustomTime">确定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { StuOpProEditPromote } from '@/utils/apis.js'
    export default {
        props: {
            ruleForm: {
                type: Object,
                default:() => {}
            },
            pageType: {
                type: String,
                default:() => ''
            }
        },
        data() {
            return {
                customTime: false,
                clickData: {
                    show: false,
                    index: null,
                    type: null,
                    percent: null,
                    timezone : '',
                    currentIndex: null,
                },
                isClick:1
            }
        },
        methods: {
            // 关闭抽屉
            closeDrawer() {

                this.reSetClickData()
                // this.clickData.show=false
                this.customTime=false
                // 当选择自定义 查看是否全部：不投放  是的话改回整日投放
                let noPut = this.ruleForm.time_frame_data.filter(item => {
                    return item.type === 0
                })
                if (this.ruleForm.show_type === 2 && noPut.length === 24) {
                    // 整天展示
                    let arr = this.ruleForm.time_frame_data.map(item => {
                        return {
                            index: item.index,
                            percent: 100,
                            timezone: item.timezone,
                            type: 1,
                        }
                    })
                    this.$emit('update:ruleForm', {
                        ...this.ruleForm,
                        show_type: 1,
                        time_frame_data: arr,
                    })
                }              
            },
            // 选择展现方式
            selectShowWay(val) {
                this.isClick=val
                if (val === 2) {
                    // 自定义
                    let arr = this.ruleForm.time_frame_data.map(item => {
                        return {
                            index: item.index,
                            percent: 0,
                            timezone: item.timezone,
                            type: 0,
                        }
                    })
                    this.$emit('update:ruleForm', {
                        ...this.ruleForm,
                        time_frame_data: arr,
                    })
                } else {
                    // 整天展示
                    let arr = this.ruleForm.time_frame_data.map(item => {
                        return {
                            index: item.index,
                            percent: 100,
                            timezone: item.timezone,
                            type: 1,
                        }
                    })
                    this.$emit('update:ruleForm', {
                        ...this.ruleForm,
                        time_frame_data: arr,
                    })
                }
            },
            // 修改单个时间段
            openSingleTime(val, index, event) {
                console.log(val)
                this.clickData.show = true
                this.clickData.index = val.index
                this.clickData.type = val.type
                this.clickData.percent = val.percent
                this.clickData.timezone = val.timezone
                this.clickData.currentIndex = index

                let winClientWidth = document.body.clientWidth;
                let x = event.clientX + 20;
                let y = event.clientY + 20;
                if (winClientWidth - 246 < x) {
                    this.$refs.clickDataRef.style.top = y + 'px'
                    this.$refs.clickDataRef.style.left = winClientWidth - 246 + 'px'
                } else {
                    this.$refs.clickDataRef.style.top = y + 'px'
                    this.$refs.clickDataRef.style.left = x + 'px'
                }
            },
            // 确定单个时间的
            sureCustomTime() {
                if (this.clickData.type === 2 && (this.clickData.percent < 30 || this.clickData.percent > 500)) {
                    this.$refs.clickDataPercent.$el.classList.add('isError')
                    return this.$message.warning('自定义折扣不得小于30%或者大于500%')
                }
                let tmp = {
                    index: this.clickData.index,
                    type: this.clickData.type,
                    percent: this.clickData.percent,
                    timezone: this.clickData.timezone,
                }
                if (this.clickData.type === 0) {
                    tmp.percent = 0
                }else if(this.clickData.type === 1){
                    console.log(123)
                    tmp.percent=100
                } else {
                    tmp.percent = this.clickData.percent
                }
                let arr = JSON.parse(JSON.stringify(this.ruleForm.time_frame_data))
                arr[this.clickData.currentIndex] = tmp
                if (this.clickData.type === 0) {
                    this.$emit('update:ruleForm', {
                        ...this.ruleForm,
                        show_type: 2,
                        time_frame_data: arr
                    })
                } else {
                    this.$emit('update:ruleForm', {
                        ...this.ruleForm,
                        time_frame_data: arr
                    })
                }
                this.reSetClickData()
            },
            // 重置确定时间的
            reSetClickData() {
                this.clickData = {
                    show: false,
                    index: null,
                    type: null,
                    percent: null,
                    timezone : '',
                    currentIndex: null,
                }
            },
            // 确定时间设置按钮
            closeCustomTime() {
                // 当选择自定义 查看是否全部：不投放  当自定义，不可以全部不投放
                let noPut = this.ruleForm.time_frame_data.filter(item => {
                    return item.type === 0
                })
                if (this.ruleForm.show_type === 2 && noPut.length === 24) {
                    return this.$message.warning('选择自定义，时段设置至少设置一个')
                } else {
                    if (this.pageType === 'list') {
                        let params = {
                            id: this.ruleForm.id,
                            show_type: this.ruleForm.show_type,
                            time_frame_data: this.ruleForm.time_frame_data,
                        }
                        StuOpProEditPromote(params).then((res) => {
                            this.$message.success(res.msg)
                            this.$emit('getList')
                        }).catch((err) => {})
                    }
                    this.clickData.show = false
                    this.customTime = false;
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .custom-budget-content {
        padding: 40px 60px;
        height: 100%!important;
        .title {
            // font-size: 24px;
            // font-weight: bold;
            // margin-bottom: 40px;
            color: #333;
            font-size: 24px;
            padding-bottom: 20px;
        }
        & > .item {
            color: #989898;
            font-size: 18px;
            margin-bottom: 20px;
        }
        .operate-box {
            .btn-red {
                position: absolute;
                right: 60px;
                bottom: 100px;
            }
        }
    }
    .table-time {
        margin-top: 30px;
        margin-bottom: 20px;
        .line {
            display: flex;
            border-top: 1px solid #EBEEF5;
            &.header {
                background: #F8F8F8;
                .item {
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    color: #14141C;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            &:last-child {
                border-bottom: 1px solid #EBEEF5;
            }
            &.line-body {
                .item {
                    display: flex;
                    padding: 12px 10px;
                }
            }
        }
        .line-left {
            width: 120px;
            line-height: 60px;
            border-left: 1px solid #EBEEF5;
            border-right: 1px solid #EBEEF5;
            text-align: center;
        }
        .line-right {
            width: 1%;
            flex: 1;
            display: flex;
            .item {
                width: 1%;
                flex: 1;
                border-right: 1px solid #EBEEF5;
                .timer {
                    cursor: pointer;
                    width: 1%;
                    flex: 1;
                    height: 36px;
                    background: #fff;
                    border: 1px solid #eee;
                    position: relative;
                    .timer-hover {
                        display: none;
                        position: absolute;
                        top: -84px;
                        left: -120px;
                        background: #fff;
                        z-index: 1;
                        padding: 10px 10px 10px 20px;
                        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
                        border-radius: 4px;
                        border: 1px solid #DFDFDF;
                        width: 200px;
                        font-size: 18px;
                        box-sizing: border-box;
                    }
                    &.current {
                        background: #FFECC8;
                        border-color: #fff;
                        border-top-color: #FFECC8;
                        border-bottom-color: #FFECC8;
                    }
                    &:hover {
                        .timer-hover {
                            display: block;
                        }
                    }
                }
                .timer + .timer {
                    border-left: none;
                }
            }
        }
    }
    .custom-time-wrapper {
        position: fixed;
        top: 50px;
        right: 50px;
        z-index: 9999;
        background: #fff;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        border: 1px solid #DFDFDF;
        padding: 20px 20px 30px;
        width: 246px;
        box-sizing: border-box;
        .title {
            font-size: 18px;
            margin-bottom: 20px;
        }
        .bottom-box {
            margin-left: 30px;
        }
    }
    .radioGroup-red {
        &.one-line {
            .el-radio {
                display: block;
                margin-bottom: 22px;
            }
            .number-input {
                width: 136px;
                margin-left: 30px;
                margin-bottom: 22px
            }
        }
    }
    .isclick{
        color: #1E63F1
    }
</style>